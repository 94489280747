/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/PagePage";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import PageBuilderFragment from "@fragments/PageBuilderFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query PageQuery($uri: [String]) {
    craft {
      entry(section: "page", uri: $uri) {
        ... on Craft_page_default_Entry {
          # hero
          ancestors {
            uid
            title
            uri
          }
          heroHeading: heading0
          heroDescriptor: descriptor0
          heroButton: link0 {
            url
            text
          }
          heroSecondaryLink: link1 {
            url
            text
          }
          heroImage: image0 {
            ...ImageFragment
          }
          video: heading2
          watermark: boolean1
          noHeight: boolean2
          theme
          # pageBuilder
          pageBuilder {
            ...PageBuilderFragment
          }
          # meta
          uid
          title
          url
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          hideCta: boolean0
          hideNewsletter: boolean4
          ctaHeading: heading1
          ctaDescriptor: descriptor1
          ctaButton: link2 {
            text
            url
          }
          ctaLink: link3 {
            text
            url
          }
          ctaImage: image1 {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    // hero
    ancestors,
    heroHeading,
    heroDescriptor,
    heroButton,
    heroSecondaryLink,
    heroImage,
    video,
    watermark,
    noHeight,
    theme,
    // pageBuilder
    pageBuilder,
    // meta
    uid,
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    hideCta,
    hideNewsletter,
    ctaHeading,
    ctaDescriptor,
    ctaButton,
    ctaLink,
    ctaImage,
  } = entry;
  return {
    hero: {
      crumbs: [...ancestors, { uid, title }],
      heading: heroHeading,
      descriptor: heroDescriptor,
      button: heroButton,
      secondaryLink: heroSecondaryLink,
      image: resolveImage(heroImage),
      video,
      watermark,
      noHeight,
      theme,
    },
    pageBuilder: resolvePageBuilder(pageBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
    },
    hideCta,
    otherCta: {
      heading: ctaHeading,
      descriptor: ctaDescriptor,
      button: ctaButton,
      link: ctaLink,
      image: resolveImage(ctaImage),
    },
    hideNewsletter,
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="PageDefaultQuery"
    {...props}
  />
);

export default Page;
